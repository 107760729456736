export const config = {
    version: '3.2.9',
    url: process.env.REACT_APP_URL,
    api: {
        baseUrl: process.env.REACT_APP_API_URL
    },
    authProviders: {
        fb: {
            appId: process.env.REACT_APP_FACEBOOK_APP_ID,
        },
        google: {
            clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        },
    },
    languages: [
        {
            name: 'lt',
            title: 'Lietuvių',
        },
        {
            name: 'en',
            title: 'English',
        }
    ],
    defaultLanguage: 'lt',
    units: {
        weight: 'kg',
    }
};
